import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache, no-store, must-revalidate',
		Pragma: 'no-cache',
		Expires: 0,
	},
});

export const Get = async <T>(
	url: string,
	config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
	return await instance.get(url, config);
};

export const Post = async <T>(
	url: string,
	data?: any,
	config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
	return await instance.post(url, data, config);
};

// request interceptors
instance.interceptors.request.use(
	(config) => {
		const newConfig = { ...config };
		// token auto push
		const accessToken = localStorage.getItem('accessToken');
		if (!!accessToken) {
			newConfig.headers.Authorization = 'Bearer ' + accessToken;
		}

		return newConfig;
	},
	async (err) => {
		return await Promise.reject(err);
	},
);
