import React from "react";

const Randing03 = () => {
  return (
    <React.Fragment>
      <img src="/static/randing/GoodPharm_Randing_03.png" alt="" />
    </React.Fragment>
  );
  };
  
  export default Randing03;
  