import React from "react";

const Coffee = () => {
  return (
    <React.Fragment>
      <img src="/static/coffee/coffee_detail.png" alt="" />
    </React.Fragment>
  );
};

export default Coffee;
