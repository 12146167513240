import {Bridge} from './bridge';
import {api} from '../api';
import {PromotionParams} from '../api/chat/types';
import {isAxiosError} from 'axios';

export const handleClickFindPharmacy = (bridge: Bridge, promotionId: number) => {
  bridge.findEventPharmacy(promotionId)
}

const PROMOTION_LINK = 'https://app.link.goodpharm.kr/';
export const handleClickShare = (bridge: Bridge, promotionCode: string) => {
  bridge.shareEvent(PROMOTION_LINK + promotionCode)
}

type ErrorType = 'NO_PHARMACY' | 'BLOCKED_PHARMACY';
type IsEnablePurchase = (bridge: Bridge, onSuccess: (storeId: string, channelUrl: string) => void, onError: (err: ErrorType) => void) => void;
export const isEnablePurchase: IsEnablePurchase = async (bridge, onSuccess, onError) => {
  const accessToken = localStorage.getItem('accessToken');
  const channelUrl = localStorage.getItem('channelUrl') ?? '';
  let storeId = '';

  if (accessToken === null) {
    bridge.getUserInfo();
  }

  if (channelUrl === '') {
    onError('NO_PHARMACY')
    return;
  }

  try {
    const response = await api.users.getFavoritePharm();
    storeId = response.storeId;
  } catch (e) {
    onError('NO_PHARMACY');
    return;
  }

  try {
    const { blocks } = await api.users.getBlockedPharm({ storeId });
    if (blocks) {
      onError('BLOCKED_PHARMACY')
      return;
    }
  } catch (e) {
    return;
  }

  onSuccess(storeId, channelUrl);
  return;
}


export const sendPurchaseMessage = async (
	bridge: Bridge,
	params: PromotionParams,
	onError: (message: string) => void,
) => {
	await api.chat
		.postPromotion(params)
		.then(() => {
			bridge.done();
		})
		.catch((err) => {
			if (isAxiosError(err)) {
				onError(err?.response?.data?.message);
			}
		});
};