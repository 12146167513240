const setToken = (accessToken: string) => {
	window.localStorage.setItem('accessToken', accessToken);
};

const setChannelUrl = (channelUrl: string) => {
	window.localStorage.setItem('channelUrl', channelUrl);
};

const clearStorage = () => {
	window.localStorage.clear();
};

export { setToken, setChannelUrl, clearStorage };
