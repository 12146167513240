import { useCallback, useState } from 'react';
import { Modal, ModalProps } from '../../../../components/Modal';
import styled from 'styled-components';
import { PromotionProduct } from '../../../../api/promotions/types';

interface QuantityModalProps {
	product: PromotionProduct;
	onSubmit: (quantity: number) => void;
	handleClose: () => void;
}
const MAX_QUANTITY = 99;

const QuantityModal = ({
	isOpen,
	product,
	onSubmit,
	handleClose,
}: QuantityModalProps & Pick<ModalProps, 'isOpen'>) => {
	const [quantity, setQuantity] = useState(1);

	const increment = useCallback(() => {
		setQuantity((prev) => ++prev);
	}, []);

	const decrement = useCallback(() => {
		if (quantity <= 1) {
			handleClose();
			return;
		}
		setQuantity((prev) => --prev);
	}, [handleClose, quantity]);
	if (!product) return <></>;

	return (
		<Modal
			isOpen={isOpen}
			title=""
			content={''}
			buttonProps={[
				{
					primary: false,
					text: '취소',
					onClick: () => {
						setQuantity(1);
						handleClose();
					},
				},
				{
					primary: true,
					text: '구매 요청',
					onClick: () => {
						onSubmit(quantity);
						setQuantity(1);
						handleClose();
					},
				},
			]}
		>
			<Wrapper>
				<header>
					<h2>수량 선택</h2>
				</header>
				<div className="item">
					<picture>
						<img src={product.imageUrl} alt={product.name} />
					</picture>
					<div className="inner">
						<h3>{product.name}</h3>
						<div className="controller">
							<button onClick={decrement}>
								<img
									src={
										'/static/icons/' +
										(quantity === 1 ? 'trash.svg' : 'minus.svg')
									}
									alt=""
								/>
							</button>
							<span className="quantity">{quantity}</span>
							<button onClick={increment} disabled={quantity >= MAX_QUANTITY}>
								<img src="/static/icons/plus.svg" alt="" />
							</button>
						</div>
					</div>
				</div>
				<footer>
					총{' '}
					<span className="price">
						{(product.discountPrice * quantity).toLocaleString()}
					</span>
					원
				</footer>
			</Wrapper>
		</Modal>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	header {
		padding: 20px 20px 8px;
		h2 {
			font-size: 20px;
			line-height: 32px;
			letter-spacing: -0.3px;
			font-weight: 700;
		}
	}
	.item {
		display: flex;
		align-items: flex-start;
		padding: 16px;
		gap: 1.2rem;
		picture {
			width: 60px;
			aspect-ratio: 1/1;
			border: 1px solid #e9ecef;
			border-radius: 6px;
			overflow: hidden;
			img {
				object-fit: cover;
			}
		}
		.inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 6px;
			h3 {
				font-size: 16px;
				line-height: 24px;
				font-weight: 500;
			}
			.controller {
				border: 1px solid #dde1e6;
				display: flex;
				button,
				.quantity {
					width: 28px;
					aspect-ratio: 1/1;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				button {
					&:disabled {
						opacity: 0.4;
					}
					img {
						width: max-content;
					}
					&:first-child {
						border-right: 1px solid #dde1e6;
					}
					&:last-child {
						border-left: 1px solid #dde1e6;
					}
				}
				.quantity {
					font-size: 14px;
					line-height: 20px;
					color: #343a40;
				}
			}
		}
	}
	footer {
		text-align: right;
		border-top: 1px solid #e9ecef;
		padding: 12px 16px;
		font-size: 15px;
		line-height: 22px;
		font-weight: 500;
		color: #343a40;
		.price {
			font-size: 18px;
			line-height: 28px;
			font-weight: 700;
			color: #0ab4ad;
		}
	}
`;

export default QuantityModal;
