import React from "react";

const OrderCoffee = () => {
	return (
		<React.Fragment>
			<img src="/static/orderCoffee/order_coffee.jpg" alt="" />
		</React.Fragment>
	);
};

export default OrderCoffee;
