import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getMobileDevice, useBridge } from '../../utils/useBridge';
import { Bridge, bridgeEvent } from '../../utils/bridge';
import { clearStorage } from '../../utils/localStorage';
import { usePromotion } from '../../utils/usePromotion';
import {
	handleClickFindPharmacy,
	handleClickShare,
	isEnablePurchase,
	sendPurchaseMessage,
} from '../../utils/promotion';
import { AlertModal } from '../../components/AlertModal';
import { FindPharmacyModal } from '../../components/FindPharmacyModal';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BLOCKED_PHARMACY } from './const';
import { PromotionProduct } from '../../api/promotions/types';

const PROMOTION_CODE = 'vita500ace';

export const Vita = () => {
	const navigate = useNavigate();
	const isMobile = getMobileDevice() !== undefined;
	const bridge: Bridge = useBridge();
	const {
		id: promotionId,
		products,
		url,
		popupImageUrl,
	} = usePromotion(PROMOTION_CODE);
	const [findModalOpen, setFindModalOpen] = useState(false);
	const [alert, setAlert] = useState<{
		isOpen: boolean;
		content: string;
	}>({ isOpen: false, content: '' });

	useEffect(() => {
		function handleCustomEvent(event: Event) {
			bridgeEvent.getUserInfo(event as CustomEvent);
		}

		window.addEventListener('getUserInfo', handleCustomEvent as EventListener);
		return () => {
			window.removeEventListener(
				'getUserInfo',
				handleCustomEvent as EventListener,
			);
			clearStorage();
		};
	}, []);

	useEffect(() => {
		if (isMobile && bridge.getUserInfo) {
			bridge.getUserInfo();
		}
	}, [bridge, isMobile]);

	const handleClickPurchase = (product: PromotionProduct) => {
		isEnablePurchase(
			bridge,
			(storeId, channelUrl) => {
				sendPurchaseMessage(
					bridge,
					{
						storeId,
						channelUrl,
						promotionId,
						promotionProducts: [
							{
								promotionProductId: product.id,
								quantity: 1,
							},
						],
					},
					(message) => {
						setAlert({
							isOpen: true,
							content: message,
						});
					},
				);
			},
			(err) => {
				if (err === 'NO_PHARMACY') {
					setFindModalOpen(true);
				}

				if (err === 'BLOCKED_PHARMACY') {
					setAlert({
						isOpen: true,
						content: BLOCKED_PHARMACY,
					});
				}
			},
		);
	};

	return (
		<>
			<Helmet>
				<title>{'약국에만 있는 비타500제로 한 병에 350원!'}</title>
				<meta
					name="description"
					content={'약국에만 있는 비타500제로 한 병에 350원!'}
				/>
				<meta
					property="og:title"
					content={'약국에만 있는 비타500제로 한 병에 350원!'}
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content={popupImageUrl ?? '/static/vita/ogimage.jpg'}
				/>
				<meta property="og:url" content={url} />
			</Helmet>
			<Container>
				<img alt={'vita'} src={'/static/vita/contents.png'} />
				<ButtonWrapper>
					{isMobile ? (
						<>
							<Button
								type={'button'}
								onClick={() => {
									if (products[0]) {
										handleClickPurchase(products[0]);
									}
								}}
							>
								<img
									alt={'purchase'}
									src={'/static/vita/purchase_button.png'}
								/>
							</Button>
							<Button
								type={'button'}
								onClick={() => {
									handleClickFindPharmacy(bridge, promotionId);
								}}
							>
								<img
									alt={'find_pharm'}
									src={'/static/vita/find_pharmacy_button.png'}
								/>
							</Button>
						</>
					) : (
						<Button
							type={'button'}
							onClick={() => {
								navigate('/service/guide');
							}}
						>
							<img
								alt={'service_info'}
								src={'/static/vita/service_info_button.png'}
							/>
						</Button>
					)}
				</ButtonWrapper>
				{isMobile ? (
					<Button
						type={'button'}
						onClick={() => {
							handleClickShare(bridge, PROMOTION_CODE);
						}}
					>
						<img alt={'share_url'} src={'/static/vita/share_url_button.png'} />
					</Button>
				) : (
					<></>
				)}
				<img alt={'terms_of_use'} src={'/static/vita/terms_of_use.png'} />
			</Container>
			<AlertModal
				{...alert}
				onClick={() => {
					setAlert({ isOpen: false, content: '' });
				}}
			/>
			<FindPharmacyModal
				isOpen={findModalOpen}
				onClick={() => {
					handleClickFindPharmacy(bridge, promotionId);
				}}
				onCancel={() => {
					setFindModalOpen(false);
				}}
			/>
		</>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ButtonWrapper = styled.div`
	display: flex;
	padding: 32px 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	background-color: #fefaef;
`;

const Button = styled.button`
	display: inline-block;
	padding: 0;
	margin: 0;
	vertical-align: top;

	> img {
		display: block;
	}
`;
