import styled from "styled-components";

const Userguide = () => {
  const moveToGoodPharmBlog = () => {
    if (window.confirm("우리 동네 굿팜 약국 찾으러 이동하시겠어요?")) {
      window.open(
        "https://m.blog.naver.com/PostList.naver?blogId=healthport_korea&categoryNo=7&proxyReferer="
      );
    }
  };
  return (
    <Container>
      <div className="contents-container">
        <img className="img" src="/static/userguide/group_2.png" alt="" />
      </div>

      <div className="contents-container">
        <img
          className="step01-img"
          src="/static/userguide/group_16.png"
          alt=""
        />
      </div>

      <div className="contents-container">
        <button type="button" onClick={() => moveToGoodPharmBlog()}>
          <img src="/static/userguide/group_4.png" alt="" />
        </button>
      </div>

      <div className="contents-container">
        <img className="img" src="/static/userguide/group_9.png" alt="" />
      </div>

      <div className="contents-container">
        <img className="img" src="/static/userguide/group_10.png" alt="" />
      </div>

      <div className="contents-container">
        <img className="img" src="/static/userguide/group_11.png" alt="" />
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .step01-img {
    width: 220px;
    height: auto;
    margin: 0 auto;
  }
  button {
    width: 200px;
  }
`;
export default Userguide;
