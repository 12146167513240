import styled from "styled-components";

const NotFound = () => {
  return (
    <Container>
      <p className="number">404</p>
      <h2>Not Found</h2>
      <p>The resource requested could not be found on this server</p>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.6rem;
  .number {
    font-size: 12rem;
    line-height: 1;
    font-weight: bold;
  }
  h2 {
    font-size: 3rem;
  }
`;

export default NotFound;
