import { setChannelUrl, setToken } from './localStorage';

interface BridgeEvent {
	getUserInfo: (event: CustomEvent) => void;
}

export const bridgeEvent: BridgeEvent = {
	getUserInfo(event: CustomEvent) {
		const { accessToken, channelUrl } = JSON.parse(
			JSON.stringify((event as CustomEvent).detail['data']),
		);
		if (accessToken) {
			setToken(accessToken);
		}
		if (channelUrl) {
			setChannelUrl(channelUrl);
		}
	},
};

export interface Bridge {
	getUserInfo: () => void;
	done: () => void;
	shareEvent: (promotionLink: string) => void;
	payment: ({
		promotionId,
		productId,
	}: {
		promotionId: number;
		productId: number;
	}) => void;
	findEventPharmacy: (promotionId: number) => void;
	device: 'ios' | 'android';
}

export const AndroidBridge: Bridge = {
	getUserInfo() {
		window.Android.getUserInfo();
	},
	done() {
		window.Android.done();
	},
	shareEvent(promotionLink: string) {
		window.Android.shareEvent(JSON.stringify({ promotionLink }));
	},
	payment({ promotionId, productId }) {
		window.Android.payment(JSON.stringify({ promotionId, productId }));
	},
	findEventPharmacy(promotionId: number) {
		window.Android.findEventPharmacy(JSON.stringify({ promotionId }));
	},
	device: 'android',
};

export const IosBridge: Bridge = {
	getUserInfo() {
		window.webkit.messageHandlers.getUserInfo.postMessage('true');
	},
	done() {
		window.webkit.messageHandlers.done.postMessage('true');
	},
	shareEvent(promotionLink: string) {
		window.webkit.messageHandlers.shareEvent.postMessage(
			JSON.stringify({ promotionLink }),
		);
	},
	payment({ promotionId, productId }) {
		window.webkit.messageHandlers.payment.postMessage(
			JSON.stringify({ promotionId, productId }),
		);
	},
	findEventPharmacy(promotionId: number) {
		window.webkit.messageHandlers.findEventPharmacy.postMessage(
			JSON.stringify({ promotionId }),
		);
	},
	device: 'ios',
};
