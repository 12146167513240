import { Helmet } from 'react-helmet-async';
import { getMobileDevice, useBridge } from '../../../utils/useBridge';
import { Bridge, bridgeEvent } from '../../../utils/bridge';
import { usePromotion } from '../../../utils/usePromotion';
import React, { useEffect, useMemo, useState } from 'react';
import { clearStorage } from '../../../utils/localStorage';
import {
	handleClickFindPharmacy,
	handleClickShare,
	isEnablePurchase,
	sendPurchaseMessage,
} from '../../../utils/promotion';
import { BLOCKED_PHARMACY } from './../const';
import { AlertModal } from '../../../components/AlertModal';
import { FindPharmacyModal } from '../../../components/FindPharmacyModal';
import styled from 'styled-components';
import { PromotionProduct } from '../../../api/promotions/types';
import Item from './components/Item';

const PROMOTION_CODE = 'reedle';

export type ProductType = 'REEDLE' | 'PDRN';
const PRODUCT_TYPE: Record<ProductType, string> = {
	REEDLE: '리들샷',
	PDRN: 'PDRN',
} as const;

const INITIAL_PRODUCTS: Record<ProductType, PromotionProduct[]> = {
	REEDLE: [],
	PDRN: [],
};

const Reedle = () => {
	const isMobile = getMobileDevice() !== undefined;
	const bridge: Bridge = useBridge();
	const {
		id: promotionId,
		products,
		url,
		popupImageUrl,
		joinCouponPrice,
	} = usePromotion(PROMOTION_CODE);

	const { PDRN, REEDLE } = useMemo(
		() =>
			products
				? products.reduce(
						(acc, cur) => {
							let type: ProductType | undefined = undefined;
							const isReedle = cur.name
								.toLocaleLowerCase()
								.includes(PRODUCT_TYPE.REEDLE.toLocaleLowerCase());
							const isPdrn = cur.name
								.toLocaleLowerCase()
								.includes(PRODUCT_TYPE.PDRN.toLocaleLowerCase());
							if (isReedle && !isPdrn) type = 'REEDLE';
							if (isPdrn) type = 'PDRN';
							if (type) return { ...acc, [type]: [...acc[type], cur] };
							return acc;
						},
						{ ...INITIAL_PRODUCTS },
					)
				: INITIAL_PRODUCTS,
		[products],
	);

	const [findModalOpen, setFindModalOpen] = useState(false);
	const [alert, setAlert] = useState<{
		isOpen: boolean;
		content: string;
	}>({ isOpen: false, content: '' });

	useEffect(() => {
		function handleCustomEvent(event: Event) {
			bridgeEvent.getUserInfo(event as CustomEvent);
		}

		window.addEventListener('getUserInfo', handleCustomEvent as EventListener);
		return () => {
			window.removeEventListener(
				'getUserInfo',
				handleCustomEvent as EventListener,
			);
			clearStorage();
		};
	}, []);

	useEffect(() => {
		if (isMobile && bridge.getUserInfo) {
			bridge.getUserInfo();
		}
	}, [bridge, isMobile]);

	const handleClickPurchase = (product: PromotionProduct) => {
		isEnablePurchase(
			bridge,
			(storeId, channelUrl) => {
				sendPurchaseMessage(
					bridge,
					{
						storeId,
						channelUrl,
						promotionId,
						promotionProducts: [
							{
								promotionProductId: product.id,
								quantity: 1,
							},
						],
					},
					(message) => {
						setAlert({
							isOpen: true,
							content: message,
						});
					},
				);
			},
			(err) => {
				if (err === 'NO_PHARMACY') {
					setFindModalOpen(true);
				}

				if (err === 'BLOCKED_PHARMACY') {
					setAlert({
						isOpen: true,
						content: BLOCKED_PHARMACY,
					});
				}
			},
		);
	};
	return (
		<React.Fragment>
			<Helmet>
				<title>{'VT리들샷 60% 세일'}</title>
				<meta name="description" content={'VT리들샷 60% 세일'} />
				<meta property="og:title" content={'VT리들샷 60% 세일'} />
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content={popupImageUrl ?? '/static/vita/ogimage.jpg'}
				/>
				<meta property="og:url" content={url} />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
				/>
			</Helmet>
			<Container>
				<img src="/static/reedle/img--01.png" alt="" />
				<img src="/static/reedle/img--02.png" alt="" />
				<div id="reedle">
					<img src="/static/reedle/img--03.png" alt="" />
					<img src="/static/reedle/img--04.png" alt="" />
					<ul className="items">
						{REEDLE.map((item) => (
							<Item
								key={'item--' + item.id}
								product={item}
								onClick={(product) => {
									handleClickPurchase(product);
								}}
								type="REEDLE"
								joinCouponPrice={joinCouponPrice}
							/>
						))}
						<img src="/static/reedle/img--05.png" alt="" />
					</ul>
				</div>
				<div id="pdrn">
					<img src="/static/reedle/img--06.png" alt="" />
					<img src="/static/reedle/img--07.png" alt="" />
					<ul className="items">
						{PDRN.map((item) => (
							<Item
								key={'item--' + item.id}
								product={item}
								onClick={(product) => {
									handleClickPurchase(product);
								}}
								type="PDRN"
								joinCouponPrice={joinCouponPrice}
							/>
						))}
						<img src="/static/reedle/img--08.png" alt="" />
					</ul>
				</div>
				<img src="/static/reedle/img--09.png" alt="" />
				{isMobile && (
					<CopyButton
						onClick={() => {
							handleClickShare(bridge, PROMOTION_CODE);
						}}
					>
						<img src="/static/reedle/img--10.png" alt="" />
					</CopyButton>
				)}
				<img src="/static/reedle/img--11.png" alt="" />
			</Container>
			<AlertModal
				{...alert}
				onClick={() => {
					setAlert({ isOpen: false, content: '' });
				}}
			/>
			<FindPharmacyModal
				isOpen={findModalOpen}
				onClick={() => {
					handleClickFindPharmacy(bridge, promotionId);
				}}
				onCancel={() => {
					setFindModalOpen(false);
				}}
			/>
		</React.Fragment>
	);
};

export default Reedle;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	.items {
		padding: 0 4.26vw;
		display: flex;
		flex-direction: column;
		gap: 4.26vw;
	}
	#reedle {
		padding: 12.8vw 0 6.13vw;
		background-color: #43484d;
	}
	#pdrn {
		background-color: #50565c;
		padding: 48px 0 20px;
	}
`;

const CopyButton = styled.button`
	img {
		vertical-align: bottom;
	}
`;
