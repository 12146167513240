import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { createPortal } from 'react-dom';

export const MODAL_ROOT = 'goodpharm-modal-root';
export const ModalRoot = (): ReactElement => (
	<div id={MODAL_ROOT} className={MODAL_ROOT} />
);

export type ModalProps = {
	isOpen: boolean;
	title?: string;
	content?: string;
	children?: React.ReactNode;
	buttonProps: { primary: boolean; onClick: () => void; text: string }[];
};
export const Modal = ({
	isOpen,
	title = '안내',
	content,
	buttonProps,
	children,
}: ModalProps) => {
	if (!isOpen) {
		return null;
	}

	return createPortal(
		<Container>
			<Wrapper>
				<ContentWrapper>
					{(title || content) && (
						<Content>
							<Label className={'title'}>{title}</Label>
							<Label className={'content'}>{content}</Label>
						</Content>
					)}
					{children}
					<Footer>
						{buttonProps.map(({ primary, onClick, text }, idx) => (
							<Button
								key={idx}
								type={'button'}
								$primary={primary}
								onClick={onClick}
							>
								{text}
							</Button>
						))}
					</Footer>
				</ContentWrapper>
			</Wrapper>
		</Container>,
		document.getElementById(MODAL_ROOT)!,
	);
};

const Container = styled.div`
	position: fixed;
	z-index: 1300;
	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
`;

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100000;
	background-color: rgba(0, 0, 0, 0.5);
`;

const ContentWrapper = styled.div`
	display: flex;
	width: 300px;
	flex-direction: column;
	align-items: flex-start;

	border-radius: 20px;
	background-color: #fff;
	box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 24px 22px;
`;

const Label = styled.span`
	font-style: normal;

	.title {
		align-self: stretch;
		color: #343a40;

		/* Title 1/Bold */
		font-size: 20px;
		font-weight: 700;
		line-height: 32px; /* 160% */
		letter-spacing: -0.3px;
	}

	.content {
		color: #5e646c;
		white-space: break-spaces;
		/* Title 3/Regular */
		font-size: 16px;
		font-weight: 400;
		line-height: 24px; /* 150% */
	}
`;

const Footer = styled.div`
	display: flex;
	gap: 8px;
	padding: 0 20px 20px;
	align-self: stretch;
`;

const Button = styled.button<{ $primary: boolean }>`
	display: flex;
	height: 44px;
	padding: 0 16px;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	border-radius: 8px;

	text-align: center;

	/* Label 1/Medium */
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px; /* 100% */

	${({ $primary }) =>
		$primary
			? css`
					background: #23c5be;
					color: #fff;
				`
			: css`
					background: #f1f3f5;
					color: #5e646c;
				`}
`;
