export const NO_FAVORITE_PHARM =
	'단골 약국 등록하지 않았습니다. 이벤트 참여가 가능한 약국을 찾아보시겠어요?';

export const NO_EVENT_PHARM =
	'등록된 약국이 이벤트 참여 약국이 아닙니다. 이벤트 참여가 가능한 약국을 찾아보시겠어요?';

export const NO_FAVORITE_PHARM_NOT_FIND =
	'단골약국을 등록하지 않았습니다.\n단골약국을 등록해야 이벤트에 참여하실수 있습니다';
	
export const BLOCKED_PHARMACY =
	'이벤트 제품을 구매할 수 없습니다. 단골 약국에 문의해주세요.';
