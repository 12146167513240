import React from 'react';
import { Modal } from './Modal';

type AlertModalProps = {
	isOpen: boolean;
	content: string;
	onClick: () => void;
};
export const AlertModal = ({ isOpen, content, onClick }: AlertModalProps) => {
	return (
		<Modal
			isOpen={isOpen}
			content={content}
			buttonProps={[
				{
					primary: true,
					onClick,
					text: '확인',
				},
			]}
		/>
	);
};
