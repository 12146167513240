import styled from 'styled-components';
import { PromotionProduct } from '../../../../api/promotions/types';
import { useMemo } from 'react';
import { fontPretendard } from '../../../../styles/fonts';
import { ProductType } from '..';

interface ItemProps {
	product: PromotionProduct;
	type: ProductType;
	joinCouponPrice: number;
	onClick: (product: PromotionProduct) => void;
}
const BACKGROUND: Record<ProductType, string> = {
	PDRN: '#05afa8',
	REEDLE: '#13af6e',
} as const;

const Item = ({ product, type, joinCouponPrice, onClick }: ItemProps) => {
	const {
		name: originalName,
		imageUrl,
		originalPrice,
		discountPrice,
	} = product;

	const [name, prefix] = useMemo(() => {
		const isPrefix = originalName.includes('+');
		if (isPrefix) {
			return originalName.split('+').map((el) => el.trim());
		}
		return [originalName];
	}, [originalName]);

	return (
		<Wrapper $img={imageUrl} $type={type}>
			<div className="inner">
				<div className="image" />
				<div className="description">
					<h4 className="name">
						{name}
						{prefix && <span className="prefix"> + {prefix}</span>}
					</h4>
					<div className="price">
						<span className="original">{originalPrice.toLocaleString()}원</span>
						<span className="discount">
							{(discountPrice - joinCouponPrice).toLocaleString()}원
						</span>
						<span className="info">웰컴쿠폰가</span>
					</div>
				</div>
			</div>
			<button
				onClick={() => {
					onClick(product);
				}}
			>
				구매
			</button>
		</Wrapper>
	);
};

const Wrapper = styled.li<{ $img: string; $type: ProductType }>`
	${fontPretendard};
	display: flex;
	background-color: #fff;
	border-radius: 3.73vw;
	overflow: hidden;
	p,
	span,
	button,
	h4 {
		font-family: 'pretendard';
	}
	.inner {
		padding: 3.73vw;
		flex: 1;
		display: flex;
		gap: 3.73vw;
		.image {
			border: 0.26vw solid #e9ecef;
			border-radius: 2.66vw;
			width: 23.46vw;
			height: 23.46vw;
			background-image: url(${({ $img }) => $img});
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
		}
		.description {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 1.33vw;
			.name {
				display: flex;
				flex-wrap: wrap;
				gap: 1vw;
				font-size: 4vw;
				line-height: 5.86vw;
				font-weight: 700;
				.prefix {
					color: ${({ $type }) => BACKGROUND[$type]};
				}
			}
			.price {
				display: flex;
				flex-direction: column;
				gap: 0.53vw;
				.original {
					font-weight: 500;
					font-size: 3.2vw;
					line-height: 3.2vw;
					color: #5e646c;
					text-decoration: line-through;
				}
				.discount {
					color: #f6185b;
					font-size: 4.8vw;
					line-height: 7.48vw;
					font-weight: 700;
				}
				.info {
					color: #f6185b;
					font-weight: 500;
					font-size: 2.93vw;
					line-height: 2.93vw;
				}
			}
		}
	}

	> button {
		background-color: ${({ $type }) => BACKGROUND[$type]};
		font-size: 3.73vw;
		line-height: 3.73vw;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.66vw 0 1.73vw;
		color: #fff;
		&::after {
			content: '';
			background-image: url('/static/reedle/prev_right.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			display: inline-block;
			width: 3.73vw;
			height: 3.73vw;
		}
	}
`;

export default Item;
