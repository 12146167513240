import { createBrowserRouter } from 'react-router-dom';
import EventContainer from './components/EventLayout';
import { Pages } from './pages';
export const router = createBrowserRouter([
	{
		path: '/',
		element: <EventContainer />,
		children: [
			{
				path: 'coffee',
				element: <Pages.Event.Coffee />,
			},
			{
				path: 'dind',
				element: <Pages.Event.Dind />,
			},
			{
				path: 'newMobilePharmacy',
				element: <Pages.Event.NewMobilePharmacy />,
			},
			{
				path: 'randing_01',
				element: <Pages.Event.Randing01 />,
			},
			{
				path: 'randing_02',
				element: <Pages.Event.Randing02 />,
			},
			{
				path: 'randing_03',
				element: <Pages.Event.Randing03 />,
			},
			{
				path: 'randing_04',
				element: <Pages.Event.Randing04 />,
			},
			{
				path: 'service',
				children: [
					{
						path: '',
						element: <Pages.Event.Service />,
					},
					{
						path: 'guide',
						element: <Pages.Event.Guide />,
					},
				],
			},
			{
				path: 'renew',
				element: <Pages.Event.Renew />,
			},
			{
				path: 'userguide',
				element: <Pages.Event.Userguide />,
			},
			{
				path: 'welcome',
				element: <Pages.Event.Welcome />,
			},
			{
				path: 'promotion',
				children: [
					{
						path: 'vita',
						element: <Pages.Promotion.Vita />,
					},
					{
						path: 'reedle',
						children: [
							{
								path: '',
								element: <Pages.Promotion.Reedle.Index />,
							},
							{
								path: 'landing',
								element: <Pages.Promotion.Reedle.Landing />,
							},
						],
					},
					{
						path: 'banobagi',
						element: <Pages.Promotion.Banobagi />,
					},
				],
			},
			{
				path: 'newmember5000',
				element: <Pages.Event.NewMember5000 />,
			},
			{
				path: 'app_pay_coffee',
				element: <Pages.Event.AppPayCoffee />,
			},
			{
				path: 'order_coffee',
				element: <Pages.Event.OrderCoffee />,
			},
			{
				path: 'app_pay_point',
				element: <Pages.Event.AppPayPoint />,
			},
			{
				path: 'easy_pay',
				element: <Pages.Event.EasyPay />,
			},

			// NotFound
			{
				path: '*',
				element: <Pages.NotFound />,
			},
		],
	},
]);
