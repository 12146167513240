import React from 'react';
import { Modal } from './Modal';

const NO_FAVORITE_PHARMACY =
	'단골 약국을 등록하지 않았습니다. 이벤트 참여가 가능한 약국을 찾아보시겠어요?';

type FindPharmacyModalProps = {
	isOpen: boolean;
	onClick: () => void;
	onCancel: () => void;
};
export const FindPharmacyModal = ({
	isOpen,
	onClick,
	onCancel,
}: FindPharmacyModalProps) => {
	return (
		<Modal
			isOpen={isOpen}
			content={NO_FAVORITE_PHARMACY}
			buttonProps={[
				{
					primary: false,
					onClick: () => {
						onCancel();
					},
					text: '취소',
				},
				{
					primary: true,
					onClick,
					text: '약국 찾기',
				},
			]}
		/>
	);
};
