import React from "react";

const NewMember5000 = () => {
	return (
		<React.Fragment>
			<img src="/static/newMember/new_member_5000.jpg" alt="" />
		</React.Fragment>
	);
};

export default NewMember5000;
