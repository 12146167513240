import React from "react";

const EasyPay = () => {
	return (
		<React.Fragment>
			<img src="/static/easyPay/easy_pay.jpg" alt="" />
		</React.Fragment>
    );
};

export default EasyPay;
