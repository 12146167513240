import NotFound from './NotFound';
import AppPayCoffee from './event/AppPayCoffee';
import AppPayPoint from './event/AppPayPoint';
import Coffee from './event/Coffee';
import Dind from './event/Dind';
import EasyPay from './event/EasyPay';
import NewMember5000 from './event/NewMember5000';
import NewMobilePharmacy from './event/NewMobilePharmacy';
import OrderCoffee from './event/OrderCoffee';
import Randing01 from './event/Randing01';
import Randing02 from './event/Randing02';
import Randing03 from './event/Randing03';
import Randing04 from './event/Randing04';
import Renew from './event/Renew';
import Service from './event/Service';
import Userguide from './event/Userguide';
import Welcome from './event/Welcome';
import Banobagi from './promotion/Banobagi';
import Reedle from './promotion/Reedle';
import Landing from './promotion/Reedle/Landing';
import { Vita } from './promotion/Vita';
import { Guide } from './service/Guide';

export const Pages = {
	Event: {
		Coffee,
		Dind,
		NewMobilePharmacy,
		Randing01,
		Randing02,
		Randing03,
		Randing04,
		Renew,
		Service,
		Userguide,
		Welcome,
		Guide,
		AppPayCoffee,
		AppPayPoint,
		EasyPay,
		NewMember5000,
		OrderCoffee,
	},
	Promotion: {
		Vita,
		Reedle: {
			Index: Reedle,
			Landing,
		},
		Banobagi,
	},
	NotFound,
};
