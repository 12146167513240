import styled from 'styled-components';
import { PromotionProduct } from '../../../../api/promotions/types';
interface ItemProps {
	product: PromotionProduct;
	joinCouponPrice: number;
	onClick: () => void;
	hasButton: boolean;
}
const Item = ({ product, joinCouponPrice, onClick, hasButton }: ItemProps) => {
	const { imageUrl, name, originalPrice, discountPrice } = product;
	return (
		<ItemWrapper>
			<div className="inner">
				<picture>
					<img src={imageUrl} alt={name} />
				</picture>
				<div>
					<h3>{name}</h3>
					<span className="caption">시카폼 클렌저 플러스</span>
					<ul>
						<li>
							<span>정상가</span>
							<span className="price">{originalPrice.toLocaleString()}</span>
						</li>
						<li>
							<span>이벤트가</span>
							<span className="price discount">
								{(discountPrice - originalPrice).toLocaleString()}
							</span>
							<span className="price">{discountPrice.toLocaleString()}</span>
						</li>
						<li className="welcome">
							<span>웰컴쿠폰가</span>
							<span className="price discount">
								-{joinCouponPrice.toLocaleString()}
							</span>
							<span className="price">
								{(discountPrice - joinCouponPrice).toLocaleString()}
							</span>
						</li>
					</ul>
				</div>
			</div>
			{hasButton && (
				<button type="button" onClick={onClick}>
					구매
				</button>
			)}
		</ItemWrapper>
	);
};

const ItemWrapper = styled.li`
	display: flex;
	background-color: #fff;
	border-radius: 14px;
	overflow: hidden;
	.inner {
		flex: 1;
		display: flex;
		align-items: flex-start;
		padding: 3.73vw;
		gap: 3.2vw;
		picture {
			border-radius: 8px;
			border: 1px solid #e9ecef;
			overflow: hidden;
			width: 17.06vw;
			aspect-ratio: 1/1;
			img {
				object-fit: cover;
			}
		}
		> div {
			display: flex;
			flex: 1;
			flex-direction: column;
			h3 {
				font-weight: 700;
				font-size: 4.8vw;
				line-height: 7.46vw;
				color: #212529;
			}
			.caption {
				text-align: left;
				display: flex;
				gap: 1.06vw;
				align-items: center;
				color: #9f0de3;
				font-weight: 700;
				font-size: 3.73vw;
				line-height: 5.33vw;
				margin-bottom: 2.13vw;
				&::before {
					content: '증정';
					width: 6.4vw;
					height: 4.26vw;
					border-radius: 3px;
					background-color: #9f0de3;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 2.66vw;
					line-height: 100%;
					color: #fff;
				}
			}
			ul {
				display: flex;
				flex-direction: column;
				gap: 1.6vw;
				li {
					display: flex;
					align-items: center;
					gap: 0.53vw;

					&:not(:has(.discount)) .price {
						margin-left: auto;
					}

					span {
						font-size: 2.93vw;
						line-height: 3.73vw;
						font-weight: 500;
						&.price {
							font-size: 3.73vw;
							&::after {
								content: '원';
							}
						}
						&.discount {
							margin-left: auto;
							border-radius: 99px;
							padding: 0.26vw 1.6vw 0.26vw 1.06vw;
							background-color: #f6185b;
							font-weight: 700;
							font-size: 2.93vw;
							color: #fff;
						}
					}
					&.welcome {
						color: #f6185b;
						span:not(.discount).price {
							font-size: 4.26vw;
							line-height: 100%;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
	button {
		background-color: #7314eb;
		font-size: 3.73vw;
		line-height: 100%;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 0.66vw 0 1.73vw;
		color: #fff;
		&::after {
			content: '';
			background-image: url('/static/reedle/prev_right.svg');
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			display: inline-block;
			width: 3.73vw;
			height: 3.73vw;
		}
	}
`;

export default Item;
