import { useEffect, useState } from 'react';
import { GetPromotionDetail } from '../api/promotions/types';
import { api } from '../api';

export function usePromotion(promotionCode: string) {
	const [details, setDetails] = useState<GetPromotionDetail>(
		{} as GetPromotionDetail,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		async function getPromotionInfo(code: string) {
			setIsError(false);
			try {
				setIsLoading(true);
				const response = await api.promotions.getPromotionDetail({ id: code });
				setDetails(response);
			} catch (err) {
				setIsError(true);
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				setIsLoading(false);
			}
		}
		if (promotionCode) {
			getPromotionInfo(promotionCode);
		}
	}, [promotionCode]);

	return { ...details, isLoading, isError, error };
}
