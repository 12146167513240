import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AlertModal } from '../../../../components/AlertModal';

const DOWNLOAD_LINK = {
	ANDROID: 'market://details?id=kr.good.pharm.customer',
	IOS: 'itms-apps://itunes.apple.com/kr/app/apple-store/id1616970174',
} as const;

const Landing = () => {
	const [alert, setAlert] = useState<{
		isOpen: boolean;
		content: string;
	}>({ isOpen: false, content: '' });

	const agent = useMemo(() => {
		const userAgent = navigator.userAgent;

		// iOS
		if (/iPad|iPhone|iPod/.test(userAgent)) {
			return 'IOS';
		}

		// Android
		if (/android/i.test(userAgent)) {
			return 'ANDROID';
		}

		return undefined;
	}, []);

	const onClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			if (!agent) {
				window.alert('디바이스가 확인되지 않습니다.');
				return;
			}

			window.open(DOWNLOAD_LINK[agent]);
		},
		[agent],
	);

	const onCopy = useCallback(() => {
		try {
			const href = window?.location?.href;
			const clipboard = navigator?.clipboard;
			if (!href) throw new Error('복사에 실패하였습니다.');
			if (clipboard) {
				clipboard.writeText(href);
			} else if (document.execCommand) {
				document.execCommand(href);
			} else throw new Error('복사에 실패하였습니다.');
			setAlert({
				isOpen: true,
				content: '주소가 클립보드에 복사되었습니다.',
			});
		} catch (err) {
			setAlert({
				isOpen: true,
				content: (err as Error).message,
			});
		}
	}, []);

	return (
		<Container>
			<article></article>

			<img src="/static/reedle/landing/web-001.png" alt="" />
			<img src="/static/reedle/landing/web-002.png" alt="" />
			<img src="/static/reedle/landing/web-003.png" alt="" />
			<img src="/static/reedle/landing/web-004.png" alt="" />
			<img src="/static/reedle/landing/web-005.png" alt="" />
			<img src="/static/reedle/landing/web-006.png" alt="" />
			<img src="/static/reedle/landing/web-007.png" alt="" />
			{!!agent && (
				<ImgButton
					onClick={() => {
						onCopy();
					}}
				>
					<img src="/static/reedle/landing/web-button.png" alt="" />
				</ImgButton>
			)}
			<img src="/static/reedle/landing/web-008.png" alt="" />
			<img src="/static/reedle/landing/web-009.png" alt="" />
			<img src="/static/reedle/landing/web-010.png" alt="" />
			<img src="/static/reedle/landing/web-011.png" alt="" />
			<img src="/static/reedle/landing/web-012.png" alt="" />
			<img src="/static/reedle/landing/web-013.png" alt="" />
			<img src="/static/reedle/landing/web-014.png" alt="" />

			<ImgButton
				onClick={() => {
					onCopy();
				}}
			>
				<img src="/static/reedle/landing/web-015.png" alt="" />
			</ImgButton>

			<img src="/static/reedle/landing/web-016.png" alt="" />

			{!!agent && (
				<ButtonWrapper>
					<button {...{ onClick }}>이벤트 참여 굿팜 앱 다운로드</button>
				</ButtonWrapper>
			)}

			<AlertModal
				{...alert}
				onClick={() => {
					setAlert({ isOpen: false, content: '' });
				}}
			/>
		</Container>
	);
};

const Container = styled.section`
	display: flex;
	flex-direction: column;
	/* min-height: 100vh; */
	article {
		/* background-color: #ff0000;
		height: 200vh; */
	}
`;

const ButtonWrapper = styled.div`
	position: sticky;
	bottom: 0;
	background-color: #fff;
	padding: 8px 16px;
	display: flex;
	button {
		flex: 1;
		background-color: #23c5be;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 52px;
		padding: 0 16px;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		color: #fff;
		border-radius: 8px;
	}
`;

const ImgButton = styled.button`
	img {
		vertical-align: bottom;
	}
`;

export default Landing;
