import { css } from 'styled-components';

export const fontJalnan = css`
	@font-face {
		font-family: 'jalnan';
		src: url('/static/fonts/jalnanGothic/JalnanGothicTTF.ttf')
			format('truetype');
		font-weight: 400;
		font-style: normal;
	}
`;

export const fontPretendard = css`
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-Thin.woff') format('woff');
		font-weight: 100;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-ExtraLight.woff') format('woff');
		font-weight: 200;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-Light.woff') format('woff');
		font-weight: 300;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-Regular.woff') format('woff');
		font-weight: 400;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-Medium.woff') format('woff');
		font-weight: 500;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-SemiBold.woff') format('woff');
		font-weight: 600;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-Bold.woff') format('woff');
		font-weight: 700;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-ExtraBold.woff') format('woff');
		font-weight: 800;
	}
	@font-face {
		font-family: 'pretendard';
		src: url('/static/fonts/pretendard/Pretendard-Black.woff') format('woff');
		font-weight: 900;
	}
`;
