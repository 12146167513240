import React from "react";

const Randing02 = () => {
  return (
    <React.Fragment>
      <img src="/static/randing/GoodPharm_Randing_02.png" alt="" />
    </React.Fragment>
  );
  };
  
  export default Randing02;
  