import { Helmet } from 'react-helmet-async';
import styled, { createGlobalStyle } from 'styled-components';
import { getMobileDevice, useBridge } from '../../../utils/useBridge';
import {
	handleClickShare,
	isEnablePurchase,
	sendPurchaseMessage,
} from '../../../utils/promotion';
import { usePromotion } from '../../../utils/usePromotion';
import Item from './components/Item';
import { AlertModal } from '../../../components/AlertModal';
import { useEffect, useState } from 'react';
import { bridgeEvent } from '../../../utils/bridge';
import { clearStorage } from '../../../utils/localStorage';
import { PromotionProduct } from '../../../api/promotions/types';
import { BLOCKED_PHARMACY, NO_FAVORITE_PHARM_NOT_FIND } from '../const';
import QuantityModal from './components/QuantityModal';
import Loading from '../../../components/Loading';

const PROMOTION_CODE = 'banobagi';

const Banobagi = () => {
	const isMobile = getMobileDevice() !== undefined;
	const bridge = useBridge();
	const {
		id: promotionId,
		products,
		url,
		popupImageUrl,
		joinCouponPrice,
		isLoading,
	} = usePromotion(PROMOTION_CODE);
	const [alert, setAlert] = useState<{
		isOpen: boolean;
		content: string;
	}>({ isOpen: false, content: '' });

	const [selectProduct, setSelectProduct] = useState<PromotionProduct | null>(
		null,
	);

	useEffect(() => {
		function handleCustomEvent(event: Event) {
			bridgeEvent.getUserInfo(event as CustomEvent);
		}

		window.addEventListener('getUserInfo', handleCustomEvent as EventListener);
		return () => {
			window.removeEventListener(
				'getUserInfo',
				handleCustomEvent as EventListener,
			);
			clearStorage();
		};
	}, []);

	useEffect(() => {
		if (isMobile && bridge.getUserInfo) {
			bridge.getUserInfo();
		}
	}, [bridge, isMobile]);

	const handleClickPurchase = (product: PromotionProduct, quantity: number) => {
		isEnablePurchase(
			bridge,
			(storeId, channelUrl) => {
				sendPurchaseMessage(
					bridge,
					{
						storeId,
						channelUrl,
						promotionId,
						promotionProducts: [
							{
								promotionProductId: product.id,
								quantity,
							},
						],
					},
					(message) => {
						setAlert({
							isOpen: true,
							content: message,
						});
					},
				);
			},
			(err) => {
				if (err === 'NO_PHARMACY') {
					setAlert({
						isOpen: true,
						content: NO_FAVORITE_PHARM_NOT_FIND,
					});
				}

				if (err === 'BLOCKED_PHARMACY') {
					setAlert({
						isOpen: true,
						content: BLOCKED_PHARMACY,
					});
				}
			},
		);
	};

	const onClickItem = (product: PromotionProduct) => {
		isEnablePurchase(
			bridge,
			() => {
				setSelectProduct(product);
			},
			(err) => {
				if (err === 'NO_PHARMACY') {
					setAlert({
						isOpen: true,
						content: NO_FAVORITE_PHARM_NOT_FIND,
					});
				}

				if (err === 'BLOCKED_PHARMACY') {
					setAlert({
						isOpen: true,
						content: BLOCKED_PHARMACY,
					});
				}
			},
		);
	};

	return (
		<>
			<UndoWidth />
			<Helmet>
				<title>{'바노바기'}</title>
				<meta property="og:type" content="website" />
				<meta property="og:title" content={'바노바기'} />
				<meta name="description" content={'바노바기'} />
				<meta
					property="og:image"
					content={popupImageUrl ?? '/static/vita/ogimage.jpg'}
				/>
				<meta property="og:url" content={url} />
			</Helmet>

			<Container>
				{isLoading && <Loading $position="fixed" />}
				<img src="/static/banobagi/img-01.png" alt="" />
				<img src="/static/banobagi/img-02.png" alt="" />
				<img src="/static/banobagi/img-03.png" alt="" />
				<ul>
					{products?.map((product) => (
						<Item
							key={`product--${product.id}`}
							product={product}
							joinCouponPrice={joinCouponPrice}
							hasButton={isMobile}
							onClick={() => {
								onClickItem(product);
							}}
						/>
					))}
				</ul>
				<img src="/static/banobagi/img-04.png" alt="" />
				<img src="/static/banobagi/img-05.png" alt="" />
				<img src="/static/banobagi/img-06.png" alt="" />
				{isMobile && (
					<button
						onClick={() => {
							handleClickShare(bridge, PROMOTION_CODE);
						}}
					>
						<img
							src="/static/banobagi/img-07.png"
							alt=""
							style={{
								verticalAlign: 'bottom',
							}}
						/>
					</button>
				)}
				<img src="/static/banobagi/img-08.png" alt="" />
			</Container>
			<QuantityModal
				isOpen={!!selectProduct}
				product={selectProduct!}
				handleClose={() => {
					setSelectProduct(null);
				}}
				onSubmit={(quantity) => {
					handleClickPurchase(selectProduct!, quantity);
				}}
			/>
			<AlertModal
				{...alert}
				onClick={() => {
					setAlert({ isOpen: false, content: '' });
				}}
			/>
		</>
	);
};

const UndoWidth = createGlobalStyle`
  main {
    max-width: 100% !important;
  }
`;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	> ul {
		display: flex;
		flex-direction: column;
		padding: 0 4.26vw;
		gap: 3.2vw;
		background-color: #e3ebf1;
	}
`;

export default Banobagi;
