import { Bridge, AndroidBridge, IosBridge } from './bridge';

export const getMobileDevice = () => {
	const userAgent = navigator.userAgent;
	const android = Boolean(/\/androidApp/g.test(userAgent));
	const ios = Boolean(/\/iOSApp/g.test(userAgent));
	return ios ? 'ios' : android ? 'android' : undefined;
};

export function useBridge() {
	const mobileDevice = getMobileDevice();
	let bridge: Bridge;

	switch (mobileDevice) {
		case 'android':
			bridge = AndroidBridge;
			break;
		case 'ios':
			bridge = IosBridge;
			break;
		default:
			bridge = {} as Bridge;
			break;
	}

	return bridge;
}
