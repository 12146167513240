import React from "react";

const Randing01 = () => {
  return (
    <React.Fragment>
      <img src="/static/randing/GoodPharm_Randing_01.png" alt="" />
    </React.Fragment>
  );
};

export default Randing01;
