import React from "react";

const NewMobilePharmacy = () => {
  return (
    <React.Fragment>
      <img src="/static/newMobilePharmacy/eventImage.png" alt="" />
    </React.Fragment>
  );
};

export default NewMobilePharmacy;
