import {
	GetPromotionDetail,
} from './types';
import { Get } from '../instance';

export const promotions = {
	getPromotionDetail: async <T = GetPromotionDetail>({
		id,
	}: {
		id: string;
	}): Promise<T> => {
		const response = await Get<T>(`/promotions/${id}`);
		return response.data;
	},
};
