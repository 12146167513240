import styled from "styled-components";

const Welcome = () => {
  const findPharmacy = () => {
    // 클릭 이벤트 필요.
    console.log("굿팜 신규가입 이벤트");
  };
  return (
    <Conatiner>
      <div className="contents-container">
        <img src="/static/welcome/header.png" alt="" />
      </div>
      <div className="contents-container">
        <img src="/static/welcome/contents.png" alt="" />
        <button type="button" onClick={() => findPharmacy()}>
          <img src="/static/welcome/button.png" alt="" />
        </button>
      </div>
      <div className="contents-container">
        <img src="/static/welcome/footer.png" alt="" />
      </div>
    </Conatiner>
  );
};

const Conatiner = styled.div`
  button {
    position: absolute;
    left: 4%;
    bottom: 4.5%;
    width: 92%;
  }
`;

export default Welcome;
