import React from "react";

const Randing04 = () => {
  return (
    <React.Fragment>
      <img src="/static/randing/GoodPharm_Randing_04.png" alt="" />
    </React.Fragment>
  );
};

export default Randing04;
