import React from "react";

const AppPayCoffee = () => {
	return (
		<React.Fragment>
			<img src="/static/appPayCoffee/app_pay_coffee.jpg" alt="" />
		</React.Fragment>
	);
};

export default AppPayCoffee;
