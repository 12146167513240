import React from "react";

const AppPayPoint = () => {
	return (
		<React.Fragment>
			<img src="/static/appPayPoint/app_pay_point.jpg" alt="" />
		</React.Fragment>
	);
};

export default AppPayPoint;
