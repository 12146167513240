import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ModalRoot } from './Modal';

const EventLayout = () => {
	return (
		<Layout>
			<Outlet />
			<ModalRoot />
		</Layout>
	);
};

const Layout = styled.main`
	margin: 0 auto;

	width: 100%;
	.contents-container {
		margin: 15px;
		position: relative;
	}
	@media screen and (min-width: 960px) {
		max-width: 900px;
	}
	@media screen and (min-width: 1264px) {
		max-width: 1185px;
	}
	@media screen and (min-width: 1904px) {
		max-width: 1785px;
	}
`;

export default EventLayout;
