import {BlockedResponse, FavoritePharmResponse} from './types';
import { Get } from '../instance';

export const users = {
	getFavoritePharm: async <T = FavoritePharmResponse>(): Promise<T> => {
		const response = await Get<T>(`/users/me/pharms/favorite`);
		return response.data;
	},
	getBlockedPharm: async <T = BlockedResponse>({storeId}: {storeId: string}): Promise<T> => {
		const response = await Get<T>(`/users/me/pharms/${storeId}/blocks`);
		return response.data;
	},
};
