import styled from "styled-components";

const Dind = () => {
  return (
    <Container>
      <img src="/static/dind/group.png" alt="" />

      <img src="/static/dind/dind_1.png" alt="" />
      <img src="/static/dind/dind_2.png" alt="" />

      <iframe
        width="100%"
        height="250"
        src="https://www.youtube.com/embed/9_LQ5td42aI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <p className="text">
        추가로 약사님의 자세한 설명을 영상으로 확인해보세요
      </p>
    </Container>
  );
};

const Container = styled.div`
  .text {
    width: 100%;
    margin: 20px 0px;
    font-size: 1.6rem;
    color: #333536;
    text-align: center;
  }
`;

export default Dind;
